import React from 'react'
import { graphql } from 'gatsby'
import { PrismicProject } from '../../graphql-types'
// eslint-disable-next-line no-unused-vars
import { prismicProjectFragment } from '../hooks/useAllProjects'
import { usePreviewData } from '../hooks/usePreview'
import { Project } from '../components/project'

interface ProjectTemplateProps {
  data: {
    prismicProject?: PrismicProject
  }
}

function ProjectTemplate({ data: staticData }: ProjectTemplateProps) {
  const data = usePreviewData(staticData)

  return <Project project={data?.prismicProject as PrismicProject} />
}

export default ProjectTemplate

export const ProjectQuery = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      ...PrismicProject
    }
  }
`
